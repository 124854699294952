import React, { useEffect } from "react";

export default function PBapp() {
  useEffect(() => {
    redirectHandle();
  }, [])

  const redirectHandle = () => {
    if (window.location.hash && window.location.hash.split('#')[1].toLowerCase() === 'us') {
      window.location.href="https://onelink.to/us_myaccount"
    } else {
      window.location.href="https://onelink.to/ca_myaccount"
    }
  }

  return (
    <div></div>
  )
}